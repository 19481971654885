import React from 'react';
import styles from "./Timeline.module.scss";
import TimelineArrow from "../../../assets/timelineArrow.svg";

interface TimelineProps {

}

const MARKS: { title: string, description: string }[] = [
	{
		title: 'Do 30 kwietnia 2022 r.',
		description: 'podjęcie uchwały o emisji akcji'
	},
	{
		title: 'Do 7 maja 2022 r.',
		description: 'podpisanie umowy objęcia udziałów'
	},
	{
		title: 'Niezwłocznie po podjęciu uchwały -',
		description: 'wpis do KRS'
	},
	{
		title: 'Niezwłocznie po wpisie do KRS -',
		description: 'wprowadzenie nowych akcjonariuszy do rejestru akcjonariuszy'
	},
]

export const Timeline: React.FC<TimelineProps> = ({ }) => {
	return (
		<div className={`container ${styles.container}`}>
			<div className={styles.line}>
				<div className={styles.heading}>
					<div className="content">
						<h4 className="highlight">Teraz</h4>
						<p>Podpisanie przedwstępnej umowy objęcia akcji</p>
					</div>
					<TimelineArrow className="arrow" />
				</div>
				{
					MARKS.map((mark, i) => (
						<div key={i} className={styles.mark}>
							<div className="line" />
							<div className="content">
								<h4>{mark.title}</h4>
								<p>{mark.description}</p>
							</div>
						</div>
					))
				}
			</div>
		</div>
	)
}