export const copyToClipboard = (text: string) => {
	if (navigator.clipboard) {
		navigator.clipboard.writeText(text);
		return;
	}

	const textField = document.createElement('textarea');
	textField.textContent = text;

	document.body.appendChild(textField);

	const selection = document.getSelection();
	const range = document.createRange();
	range.selectNode(textField);
	selection?.removeAllRanges();
	selection?.addRange(range);

	document.execCommand('copy');

	selection?.removeAllRanges();

	textField.remove();
}