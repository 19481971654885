import React from 'react';
import { Clickable } from "../../../Clickable/Clickable";
import styles from "./NextSteps.module.scss";
import { NextStepsListItem } from './NextStepsListItem/NextStepsListItem';
import { copyToClipboard } from "../../../../utils/browser";
import CopySvg from "../../../../assets/copy.svg";

interface NextStepsProps {

}

const transferData: { name: string, value: string }[] = [
	{
		name: 'Nr konta',
		value: '86 1090 1737 0000 0001 4933 3365',
	},
	{
		name: 'Nazwa firmy',
		value: 'Wizyter PSA',
	},
	{
		name: 'Adres',
		value: '60-758 Poznań ul. Grottgera 16/1',
	},
	{
		name: 'Tytuł',
		value: 'Pokrycie wartości wkładu pieniężnego za Nowe Akcje Spółki '
	}
]

export const NextSteps: React.FC<NextStepsProps> = React.memo(({ }) => {
	return (
		<div>
			<ol className={styles.list}>
				<NextStepsListItem
					label="Podpisz umowę"
				>
					<div className={styles.sign}>
						<div>
							Podpisem kwalifikowanym
						</div>
						<span>
							lub
						</span>
						<div>
							<a
								href="https://www.gov.pl/web/gov/podpisz-dokument-elektronicznie-wykorzystaj-podpis-zaufany"
								rel="noopener noreferrer"
								target="_blank"
							>
								Za pomocą Profilu Zaufanego
							</a>
						</div>
						<span>
							lub
						</span>
						<div>
							Wydrukuj, podpisz i zeskanuj
						</div>
					</div>
				</NextStepsListItem>
				<NextStepsListItem
					label="Wykonaj przelew pełnej kwoty z umowy na poniższy adres:"
				>
					<div className={styles.transfer}>
						{
							transferData.map((data, i) => (
								<div key={i} className={styles.transferRow}>
									<p>
										{data.name}:
									</p>
									<p className="highlight">
										{data.value}
									</p>
									<div onClick={() => copyToClipboard(data.value)}>
										<Clickable>
											<CopySvg />
										</Clickable>
									</div>
								</div>
							))
						}
					</div>
				</NextStepsListItem>
				<NextStepsListItem
					label={
						<span>
							Wyślij podpisane dokumenty oraz potwierdzenie przelewu na e-mail:{' '}
							<a href="mailto:akcje@wizyter.pl" target="_blank">
								<span className="highlight">
									akcje@wizyter.pl
								</span>
							</a>
						</span>
					}
				/>
			</ol>
		</div>
	)
})