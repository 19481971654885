import { useRef, useState } from "react";
import { getApiUrl } from "../../constants";
import { StocksFormData } from "./Steps/StocksForm/StocksForm";

export const useStocksForm = () => {
	const sharesRef = useRef(196);
	const setShares = (value: number) => sharesRef.current = value;

	const [fileUrl, setFileUrl] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | string>(null);
	const [initialState, setInitialState] = useState<StocksFormData>({
		city: '',
		email: '',
		firstName: '',
		flatNo: '',
		lastName: '',
		pesel: '',
		phone: '',
		postalCode: '',
		street: ''
	});

	const handleSubmit = async (formData: StocksFormData) => {
		setIsLoading(true);

		const { city, email, firstName, flatNo, lastName, pesel, phone, postalCode, street } = formData;

		const payload = {
			address: `${city} ${postalCode} ${street}/${flatNo}`,
			email,
			firstName,
			lastName,
			pesel,
			phone,
			shares: sharesRef.current
		}

		const body = JSON.stringify(payload)

		const result = await fetch(
			`${getApiUrl()}/investment`,
			{ method: "POST", body, headers: { 'Content-Type': 'application/json' } }
		);

		if (!result.ok) {
			const response = await result.json();

			if (result.status === 409) {
				setError(`Liczba dostępnych akcji: ${response.sharesLeft}`);
			} else {
				setError('Wystąpił nieoczekiwany błąd serwera');
			}

			setIsLoading(false);
			return;
		}

		const buffer = await result.arrayBuffer();

		const file = new Blob([buffer], { type: 'application/pdf' });
		const url = URL.createObjectURL(file);

		setFileUrl(url);
		window.open(url);

		setError(null);
		setIsLoading(false);
		setInitialState(formData)
	}

	const handleReset = () => {
		if (fileUrl) {
			URL.revokeObjectURL(fileUrl);
		}

		setFileUrl('');
	}

	const handleReopen = () => {
		if (!fileUrl) return;

		window.open(fileUrl);
	}

	return {
		handleReopen,
		handleReset,
		handleSubmit,
		isLoading,
		setShares,
		hasFile: !!fileUrl,
		initialState,
		setInitialState,
		error
	}
}