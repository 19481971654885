import React from 'react';
import { useStocksForm } from '../useStocksFrom';
import { NextSteps } from './NextSteps/NextSteps';
import { Step } from './Step/Step';
import styles from "./Steps.module.scss";
import { StocksForm } from './StocksForm/StocksForm';
import { StocksSelector } from './StocksSelector/StocksSelector';
import PlaneIllustration from "../../../assets/airplanes/long.svg";

interface StepsProps {

}

export const Steps: React.FC<StepsProps> = ({ }) => {
	const { handleReopen, handleReset, handleSubmit, isLoading, setShares, hasFile, initialState, error } = useStocksForm();

	return (
		<div className={styles.container}>
			<Step count={1} label="Wybierz liczbę akcji" highlight>
				<div className={styles.selectorContainer}>
					<StocksSelector onChange={(value) => setShares(value)} />
				</div>
			</Step>

			<Step count={2} label="Wprowadź swoje dane">
				{
					hasFile ? (
						<div className={styles.buttons}>
							<button className="button" onClick={handleReopen}>Pokaż umowę</button>
							<button className="button inverted sm" onClick={handleReset}>Wygeneruj nową umowę</button>
						</div>
					) : (
						<StocksForm onSubmit={handleSubmit} isLoading={isLoading} initialState={initialState} error={error} />
					)
				}
			</Step>

			<Step count={3} label="Następne kroki">
				<NextSteps />
			</Step>

			<PlaneIllustration className={styles.plane} />
		</div>
	)
}