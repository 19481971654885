import React from 'react';
import styles from "./NextStepsListItem.module.scss";

interface NextStepsListItemProps {
	label: string | JSX.Element
}

export const NextStepsListItem: React.FC<NextStepsListItemProps> = ({ label, children }) => {
	return (
		<li className={styles.item}>
			<span className={styles.label}>{label}</span>
			{children &&
				<div className={styles.content}>
					{children}
				</div>
			}
		</li>
	)
}