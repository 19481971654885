import React from 'react';
import { Helmet } from "react-helmet";
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { Timeline } from '../../components/Stocks/Timeline/Timeline';
import { Steps } from '../../components/Stocks/Steps/Steps';
import { StocksHero } from '../../components/Stocks/StocksHero/StocksHero';
import { useDefaultFooterData } from "../../hooks/useDefaultFooterData";

interface StocksProps {

}

const Stocks: React.FC<StocksProps> = ({ }) => {
	const { socials } = useDefaultFooterData();

	return (
		<>
			<Helmet>
				<title>Wizyter.pl</title>
			</Helmet>
			<div className="main-font-headers">
				<Header withRegisterButton={false} />
				<StocksHero />
				<Timeline />
				<Steps />
				<Footer socials={socials} />
			</div>
		</>
	)
}

export default Stocks;