import React, { useState } from 'react';
import styles from "./StockSelector.module.scss";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { formatPrice } from '../../../../utils/format';

interface StocksSelectorProps {
	value?: number,
	onChange: (value: number) => any;
}

export const MIN_STOCKS = 7;
export const MAX_STOCKS = 700;
export const STOCKS_IN_PACKAGE = 1;

export const STOCK_PRICE = 152;

const marks = {
	[MIN_STOCKS]: { label: formatPrice(MIN_STOCKS * STOCK_PRICE) },
	[MAX_STOCKS]: { label: formatPrice(MAX_STOCKS * STOCK_PRICE) },
}



export const StocksSelector: React.FC<StocksSelectorProps> = ({ onChange }) => {
	const [offset, setOffset] = useState(MIN_STOCKS);
	const [value, setValue] = useState(196);

	const handleChange = (value: number) => {
		setValue(value);
		onChange(value);
	}

	return (
		<div className={styles.container}>
			<div className={`${styles.top} mobile`} style={{ left: `${offset}%` }}>
				{value}
			</div>
			<div className={styles.bottom} style={{ left: `${offset}%` }}>
				{formatPrice(value * STOCK_PRICE)}
			</div>
			<Slider
				marks={marks}
				value={value}
				min={MIN_STOCKS}
				max={MAX_STOCKS}
				step={STOCKS_IN_PACKAGE}
				handle={(props) => {
					setOffset(props.offset);

					return (
						<Slider.Handle {...props} dragging={props.dragging?.toString()} className={`${props.className} ${styles.handle}`}>
							<div className={styles.top}>
								{value}
							</div>
						</Slider.Handle>
					)
				}}
				onChange={handleChange}
			/>
		</div >
	)
}