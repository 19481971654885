import styles from "./StocksForm.module.scss";
import React, { useState } from 'react';
import { Input } from "../../../Input/Input";
import { validatePesel } from "../../../../utils/validation";
import { Form } from "../../../Form/Form"

interface StocksFormProps {
	onSubmit?: (formData: StocksFormData) => any;
	isLoading?: boolean;
	initialState?: StocksFormData
	error?: string | null;
}

export interface StocksFormData {
	firstName: string;
	lastName: string;
	email: string;
	city: string;
	street: string;
	flatNo: string;
	postalCode: string;
	pesel: string;
	phone: string;
}

export const StocksForm: React.FC<StocksFormProps> = ({ onSubmit, isLoading, initialState, error }) => {
	const [validationError, setValidationError] = useState<null | string>(null);

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();

		const values = Array.from((e.target as any).elements).reduce((acc, element: any) => {
			if (element.value === undefined || !element.name) return acc;

			acc[element.name] = element.value;

			return acc;
		}, {}) as StocksFormData;

		if (!validatePesel(values.pesel)) {
			setValidationError('Niepoprawny numer PESEL');
			return;
		}

		setValidationError(null);
		onSubmit?.(values)
	}

	return (
		<Form className={styles.form} onSubmit={handleSubmit} isLoading={isLoading}>
			<h6>Dane podstawowe</h6>
			<fieldset>
				<div>
					<Input
						required
						gutterBottom
						defaultValue={initialState.firstName}
						name="firstName"
						placeholder="Imię"
					/>
					<Input
						required
						gutterBottom
						defaultValue={initialState.lastName}
						name="lastName"
						placeholder="Nazwisko"
					/>
					<Input
						required
						gutterBottom
						defaultValue={initialState.pesel}
						name="pesel"
						placeholder="Nr. PESEL"
					/>
					<Input
						required
						gutterBottom
						defaultValue={initialState.email}
						name="email"
						type="email"
						placeholder="Email"
					/>
					<Input
						required
						gutterBottom
						defaultValue={initialState.phone}
						name="phone"
						type="tel"
						placeholder="Nr. telefonu"
					/>
				</div>
			</fieldset>
			<h6>Adres zamieszkania</h6>
			<fieldset>
				<div>
					<Input
						required
						gutterBottom
						defaultValue={initialState.city}
						name="city"
						placeholder="Miasto"
					/>
					<Input
						required
						gutterBottom
						defaultValue={initialState.street}
						autoComplete="address-line1"
						name="street"
						placeholder="Ulica, nr. budynku"
					/>
					<Input
						required
						gutterBottom
						defaultValue={initialState.flatNo}
						autoComplete="address-line2"
						name="flatNo"
						placeholder="Numer lokalu"
					/>
					<Input
						name="postalCode"
						required
						defaultValue={initialState.postalCode}
						autoComplete="zip"
						placeholder="Kod pocztowy"
					/>
				</div>
			</fieldset>

			<div className={styles.actions}>
				{(validationError || error) && <p className="error">{validationError ?? error}</p>}
				<button className="button" type="submit">
					Generuj umowę
				</button>
			</div>
		</Form>
	)
}