function getMultiplier(index: number) {
	switch (index % 4) {
		case 1: return 1;
		case 2: return 3;
		case 3: return 7;
		case 0: return 9;
		default:
			throw "Something went wrong with the index calculation";
	}
}

export function validatePesel(pesel: string) {
	if (pesel === null || pesel.length !== 11)
		return false;

	const arr = pesel.split("");
	let sum: number = 0;

	for (let i: number = 0; i < arr.length - 1; i++) {
		sum += +arr[i] * getMultiplier(i + 1);
	}

	const modulo = sum % 10;
	const lastD = Number(pesel.substr(pesel.length - 1));

	return (modulo === 0) && lastD === 0 ||
		lastD === 10 - modulo;
}

