import React from 'react';
import BoyIllustration from "../../../assets/sharesHero/boy.svg";
import Line from "../../../assets/lines/line5.svg";
import styles from "./StocksHero.module.scss";

interface StocksHeroProps {

}

export const StocksHero: React.FC<StocksHeroProps> = ({ }) => {
	return (
		<div className={styles.wrapper}>
			<div className={`container ${styles.container}`}>
				<h1 className="highlight">
					Zostań wspólnikiem Wizyter PSA
				</h1>
				<BoyIllustration className={styles.illustration} />
				<p>
					Zapraszamy do wypełnienia formularza i wygenerowania umowy.
				</p>
			</div>
			<Line className={styles.line} />
		</div>
	)
}