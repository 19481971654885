import styles from "./Step.module.scss";
import React from "react";
import Line from "../../../../assets/lines/line4.svg";

interface StepProps {
	count: string | number,
	label: string,
	highlight?: boolean
}

export const Step: React.FC<StepProps> = ({ count, label, children, highlight }) => {
	const contentContainerClassName = `${styles.contentWrapper}${highlight ? ` ${styles.highlight}` : ''}`;

	return (
		<>
			<div className="container">
				<div className={styles.step}>
					<div className={styles.stepCount}>
						{count}
					</div>
					<div className={styles.stepLabel}>
						{label}
					</div>
				</div>
			</div>
			<div className={contentContainerClassName}>
				{
					highlight && (
						<>
							<Line className={styles.line1} />
							<Line className={styles.line2} />
						</>
					)
				}
				<div className="container">
					<div className={styles.content}>
						{children}
					</div>
				</div>
			</div>
		</>
	)
}